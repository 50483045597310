import React from "react";
import { Form } from "react-bootstrap";
import { printRole } from "../util";

function toRole(roleStr) {
  switch (roleStr) {
    case "admin":
      return "admin";
    case "pedidos":
      return "order";
    default:
      return "member";
  }
}

function MemberForm(props) {
  const member = props.value;

  const set = (key, value) => {
    let m = { ...member };
    m[key] = value;
    props.onChange(m);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label>Numero de socia:</Form.Label>
        <Form.Control
          type="number"
          placeholder="numero"
          value={member.num}
          onChange={(e) => set("num", parseInt(e.target.value))}
          isInvalid={!member.num}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Nombre:</Form.Label>
        <Form.Control
          placeholder="nombre"
          value={member.name}
          onChange={(e) => set("name", e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type="email"
          placeholder="email"
          value={member.email}
          onChange={(e) => set("email", e.target.value)}
          isInvalid={!member.email}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Telefono:</Form.Label>
        <Form.Control
          placeholder="telefono"
          value={member.phone}
          onChange={(e) => set("phone", e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Rol:</Form.Label>
        <Form.Select
          value={printRole(member.role)}
          onChange={(e) => set("role", toRole(e.target.value))}
        >
          <option>socia</option>
          <option>pedidos</option>
          <option>admin</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}

export default MemberForm;
