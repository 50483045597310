import React, { useState } from "react";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import PasswordForm from "./PasswordForm";
import Sender from "./Sender";

function OwnPassword() {
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  return (
    <Sender
      url="/api/member/me"
      method="PUT"
      body={{ old_password, password }}
      onSuccess={() => setPasswordChanged(true)}
    >
      <h2 className="text-center">Cambio de contraseña</h2>

      {passwordChanged && (
        <Alert variant="success">La contraseña se ha cambiado con éxito.</Alert>
      )}

      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={4}>
          Contraseña actual
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Contraseña actual"
            type="password"
            value={old_password}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Col>
      </Form.Group>

      <PasswordForm
        password={password}
        onChange={setPassword}
        setValid={setIsFormValid}
      />
      <Form.Group as={Row}>
        <Col sm={{ offset: 4, span: 8 }}>
          <Button type="submit" variant="primary" disabled={!isFormValid}>
            Cambiar la contraseña
          </Button>
        </Col>
      </Form.Group>
    </Sender>
  );
}

export default OwnPassword;
