import React, { useState, useContext } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Fetcher from "./Fetcher";
import OrderCards from "./order/OrderCards";
import MyTransactions from "./transaction/MyTransactions";
import AuthContext from "./AuthContext";
import { printMoney } from "./util";

function Dashboard() {
  const [name, setName] = useState(null);
  const [balance, setBalance] = useState(null);
  const auth = useContext(AuthContext);

  const onFetch = (member) => {
    if (member.name !== name) {
      setName(member.name);
    }
    if (member.balance !== balance) {
      setBalance(member.balance);
    }
  };

  let buyButton;
  if (!auth.disabled) {
    buyButton = (
      <LinkContainer to="/purchase">
        <Button variant="success">Comprar</Button>
      </LinkContainer>
    );
  }

  return (
    <Fetcher url={"/api/member/me"} onFetch={onFetch}>
      <Row>
        <Col xs>
          <div className="text-right">
            <h6>{name}</h6>
            <h1>{printMoney(balance)}€</h1>
          </div>
        </Col>
        <Col xs={{ offset: 0 }} md={{ offset: 1 }}>
          <br />
          {buyButton}
        </Col>
      </Row>
      <OrderCards />
      <MyTransactions />
    </Fetcher>
  );
}

export default Dashboard;
