import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Fetcher from "../Fetcher";
import { printMoney } from "../util";
import PriceEditor from "../PriceEditor";

class ProductPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      products: [],
    };
  }

  delPick(index) {
    let picks = [...this.props.picks];
    picks.splice(index, 1);
    this.props.setPicks(picks);
  }

  setAmount(index, amount) {
    let picks = [...this.props.picks];
    picks[index].amount = parseInt(amount);
    this.props.setPicks(picks);
    // trick it to redraw on each change
    this.setState({ code: this.state.code });
  }

  setPrice(index, price) {
    let picks = [...this.props.picks];
    picks[index].price = price;
    this.props.setPicks(picks);
  }

  setCode(codeStr) {
    const code = parseInt(codeStr);
    const product = this.state.products.find((p) => p.code === code);
    if (product === undefined) {
      this.setState({ code: codeStr });
    } else {
      this.pickProduct(product);
    }
  }

  pickProduct(product) {
    let picks = [...this.props.picks];
    if (picks.find((p) => product.code === p.code)) {
      return;
    }

    let amount = 0;
    if (this.props.amount) {
      amount = 1;
    }
    picks.push({
      code: product.code,
      name: product.name,
      origPrice: product.price,
      price: product.price,
      stock: product.stock,
      amount,
    });
    this.props.setPicks(picks);
    this.setState({ code: "" });
  }

  setProducts(products) {
    if (this.props.amount) {
      products = products.filter((p) => p.stock > 0);
    }
    this.setState({ products });
  }

  render() {
    const rows = this.props.picks.map((p, i) => {
      let price;
      if (!this.props.noPrice) {
        price = (
          <Col>
            {this.props.price ? (
              <PriceEditor
                value={p.price}
                onChange={(price) => this.setPrice(i, price)}
              />
            ) : (
              printMoney(p.price) + "€"
            )}
          </Col>
        );
      }
      return (
        <Form.Group key={p.code} as={Row}>
          <Col>
            <p>{p.code}</p>
          </Col>
          <Col xs={4}>
            <p>{p.name}</p>
          </Col>
          {price}
          {(this.props.amount || this.props.stock) && (
            <Col>
              <Form.Control
                type="number"
                min={this.props.amount ? "1" : Number.MIN_SAFE_INTEGER}
                max={this.props.amount ? p.stock : Number.MAX_SAFE_INTEGER}
                placeholder="cantidad"
                value={p.amount}
                onChange={(e) => this.setAmount(i, e.target.value)}
              />
            </Col>
          )}
          <Col xs={1}>
            <Button variant="danger" onClick={() => this.delPick(i)}>
              -
            </Button>
          </Col>
        </Form.Group>
      );
    });

    return (
      <Fetcher url="/api/product" onFetch={(p) => this.setProducts(p)}>
        <Row>
          <Col>
            <h6>Código</h6>
          </Col>
          <Col xs={4}>
            <h6>Nombre</h6>
          </Col>
          {!this.props.noPrice && (
            <Col>
              <h6>Precio</h6>
            </Col>
          )}
          {(this.props.amount || this.props.stock) && (
            <Col>
              <h6>Cantidad</h6>
            </Col>
          )}
          <Col xs={1}></Col>
        </Row>
        {rows}
        <Form.Group as={Row}>
          <Col>
            <Form.Control
              placeholder="codigo"
              value={this.state.code}
              onChange={(e) => this.setCode(e.target.value)}
            />
          </Col>
          <Col xs={12} sm={4}>
            <Typeahead
              id="product-name"
              placeholder="nombre"
              labelKey="name"
              options={this.state.products}
              onChange={(name) => this.pickProduct(name[0])}
              selected={[]}
            />
          </Col>
          {!this.props.noPrice && <Col xs={false} sm></Col>}
          {(this.props.amount || this.props.stock) && <Col xs={false} sm></Col>}
          <Col xs={false} sm={1}></Col>
        </Form.Group>
      </Fetcher>
    );
  }
}

export default ProductPicker;
