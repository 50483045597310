import React, { useState, useRef } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import InventaryEntry from "./InventaryEntry";
import ProductPicker from "../product/ProductPicker";
import Fetcher from "../Fetcher";
import Sender from "../Sender";

function getSupplier(suppliers, name) {
  return suppliers.find((s) => s.name === name);
}

function supplierNameToId(suppliers, name) {
  const found = getSupplier(suppliers, name);
  if (found) {
    return found.ID;
  }
  return null;
}

function CreateInventary(props) {
  const [suppliers, setSuppliers] = useState([]);
  const [comment, setComment] = useState("");
  const [supplier, setSupplier] = useState(null);
  const [picks, setPicks] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const sender = useRef(null);

  const handleClose = () => setShowConfirmation(false);

  const onSuccess = (entry) => {
    setComment("");
    setSupplier(null);
    setPicks([]);
    props.setEntry(entry);
  };

  const body = {
    comment,
    supplier_id: supplierNameToId(suppliers, supplier),
    supplier: getSupplier(suppliers, supplier),
    products: picks.map((p) => {
      return {
        code: p.code,
        price: p.price !== p.origPrice ? p.price : null,
        stock: p.amount ? p.amount : null,
        product: p,
      };
    }),
  };

  const submit = () => {
    handleClose();
    sender.current.click();
  };

  const disabled = picks.length === 0;

  const supplierOptions = [<option key=""></option>].concat(
    suppliers.map((s) => <option key={s.ID}>{s.name}</option>)
  );

  return (
    <Sender url="/api/inventary" body={body} onSuccess={onSuccess}>
      <button style={{ display: "none" }} type="submit" ref={sender} />
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={3}>
          Proveedor
        </Form.Label>
        <Col sm={9}>
          <Fetcher url="/api/supplier" onFetch={setSuppliers}>
            <Form.Select onChange={(e) => setSupplier(e.target.value)}>
              {supplierOptions}
            </Form.Select>
          </Fetcher>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={3}>
          Comentario
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            placeholder="..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Col>
      </Form.Group>
      <ProductPicker picks={picks} setPicks={setPicks} stock price />
      <Form.Group as={Row}>
        <Col>
          <div className="text-right">
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => setShowConfirmation(true)}
            >
              Añadir entrada
            </Button>
          </div>
        </Col>
      </Form.Group>

      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar la entrada de almacén</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InventaryEntry entry={body} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={disabled} onClick={submit}>
            Añadir entrada
          </Button>
        </Modal.Footer>
      </Modal>
    </Sender>
  );
}

export default CreateInventary;
