import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Col, Row, Form, Button } from "react-bootstrap";
import Sender from "../Sender";
import PriceEditor from "../PriceEditor";

function CreateProduct() {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [redirect, setRedirect] = useState(null);

  if (redirect != null) {
    return <Navigate to={"/product/" + redirect.code} />;
  }

  const body = () => {
    return {
      code: parseInt(code),
      price: price,
      stock: parseInt(stock),
      name,
    };
  };

  const disabled = isNaN(price) || isNaN(parseInt(code)) || !name;

  return (
    <div>
      <h2 className="text-center">Crear un nuevo código de producto</h2>
      <Sender url="/api/product" body={body} onSuccess={setRedirect}>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Codigo:</Form.Label>
            <Form.Control
              type="number"
              placeholder="codigo"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={10}>
            <Form.Label>Producto:</Form.Label>
            <Form.Control
              placeholder="nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Precio:</Form.Label>
            <PriceEditor value={price} onChange={setPrice} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Cantidad:</Form.Label>
            <Form.Control
              type="number"
              placeholder="cantidad"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Button variant="primary" disabled={disabled} type="submit">
          Añadir
        </Button>
      </Sender>
    </div>
  );
}

export default CreateProduct;
