import React, { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import MemberForm from "./MemberForm";
import Sender from "../Sender";

function MemberAdder() {
  const [member, setMember] = useState({
    num: "",
    name: "",
    email: "",
    phone: "",
    role: "member",
  });
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <Alert variant="success">
        {member.name} ha sido añadida al garbanzo, recibirá un email con un
        enlace para poner su contraseña y nombre de acceso.
      </Alert>
    );
  }

  const invalid = !member.num || !member.name || !member.email;

  return (
    <Sender url="/api/member" body={member} onSuccess={() => setSuccess(true)}>
      <MemberForm value={member} onChange={setMember} />
      <Button type="submit" disabled={invalid}>
        Crear socia
      </Button>
    </Sender>
  );
}

export default MemberAdder;
