import React, { useState } from "react";
import { Row, Form, Button, Alert } from "react-bootstrap";
import Sender from "./Sender";

function ResetRequest() {
  const [email, setEmail] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [success, setSuccess] = useState(false);

  const onError = (status) => {
    if (status === 400) {
      setInvalid(true);
      return true;
    }
    return false;
  };

  if (success) {
    return (
      <Alert variant="success">
        Se te ha enviado un email con un enlace para restaurar la contraseña,
        mira tu buzón y visita el enlace para recuperar la cuenta.
      </Alert>
    );
  }

  return (
    <Row className="justify-content-center">
      <Sender
        url="/api/reset"
        body={{ email }}
        onSuccess={() => setSuccess(true)}
        onError={onError}
      >
        <Form.Group>
          <Form.Label>
            Para restaurar la contraseña olvidada necesitamos tu email:
          </Form.Label>
          <Form.Control
            placeholder="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={invalid}
          />
          <Form.Control.Feedback type="invalid">
            Esta dirección de correo no corresponde con la de ninguna socia
          </Form.Control.Feedback>
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100 mt-3">
          Enviar email
        </Button>
      </Sender>
    </Row>
  );
}

export default ResetRequest;
