import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Fetcher from "../Fetcher";
import { printDate } from "../util";

function DuesList() {
  const [dues, setDues] = useState([]);

  const entries = dues.map((d) => (
    <tr key={d.ID}>
      <td>{printDate(d.date)}</td>
      <td>{d.total}</td>
    </tr>
  ));

  return (
    <Fetcher url="/api/dues/mine" onFetch={setDues}>
      <h3>Cuotas:</h3>
      <Table className="text-center" responsive>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>{entries}</tbody>
      </Table>
    </Fetcher>
  );
}

export default DuesList;
