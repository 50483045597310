import React, { useState } from "react";
import InventaryList from "./InventaryList";
import CreateInventary from "./CreateInventary";
import Fetcher from "../Fetcher";

function Inventary() {
  const [inventary, setInventary] = useState([]);

  return (
    <div>
      <h2 className="text-center">Entradas de Almacén</h2>
      <CreateInventary setEntry={(e) => setInventary([e, ...inventary])} />

      <hr />
      <Fetcher url="/api/inventary" onFetch={setInventary}>
        <InventaryList inventary={inventary} />
      </Fetcher>
    </div>
  );
}

export default Inventary;
