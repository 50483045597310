import React, { useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Fetcher from "../Fetcher";
import {
  Row,
  Col,
  Badge,
  Button,
  ButtonGroup,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import PurchaseOrder from "./PurchaseOrder";
import Sender from "../Sender";
import { printDate, printMoney, url, deepEqual } from "../util";
import AuthContext from "../AuthContext";

function getName(order_product) {
  if (order_product.product !== null) {
    return order_product.product.name;
  }
  return order_product.code;
}

function ShowOrderTransaction(props) {
  const list = props.transaction.order_purchase.map((o) => (
    <li key={o.order_product.code}>
      {getName(o.order_product)} ({o.order_product.code}): {o.amount}
    </li>
  ));
  return (
    <div>
      <h3>Mi pedido</h3>
      <ul>{list}</ul>
      <p>Total: {printMoney(-props.transaction.total)} €</p>
    </div>
  );
}

function ShowOrderResults(props) {
  let products = props.order.products.map((p) => {
    p.total = 0;
    return p;
  });
  const transactions = props.order.transactions.map((t) => {
    if (t.order_purchase === null) {
      return null;
    }
    const list = t.order_purchase.map((purchase) => {
      const i = products.findIndex((p) => p.ID === purchase.order_product_id);
      if (i === -1) {
        return null;
      }
      products[i].total += purchase.amount;
      if (purchase.amount) {
        const key =
          t.member.num.toString() + "-" + purchase.order_product_id.toString();
        return (
          <li key={key}>
            {getName(products[i])} {purchase.amount}
          </li>
        );
      }
      return null;
    });
    return (
      <li key={t.member.num}>
        {t.member.name} ({t.member.num}):
        {t.collected && <Badge bg="success">Recogido</Badge>}
        <ul>{list}</ul>
      </li>
    );
  });

  const product_list = products.map((p) => (
    <li key={p.code}>
      {getName(p)}: {p.total}
    </li>
  ));
  return (
    <div>
      <h3>Productos pedidos</h3>
      <ul>{product_list}</ul>

      <h3>Pedidos</h3>
      <ul>{transactions}</ul>
    </div>
  );
}

function ShowOrder() {
  const { id } = useParams();
  const [order, setOrder] = useState({
    products: [],
    transactions: [],
  });
  const [transaction, setTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [error, setError] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const auth = useContext(AuthContext);

  const onSend = (transaction) => {
    setTransaction(transaction);
    setRefetch(refetch + 1);
  };

  const setData = (data) => {
    if (!deepEqual(data.order, order)) {
      setOrder(data.order);
    }
    if (!deepEqual(data.transaction, transaction)) {
      setTransaction(data.transaction);
    }
  };

  if (redirect) {
    return <Navigate to="/" />;
  }

  let expired;
  if (!order.active) {
    expired = <Badge variant="info">finalizado</Badge>;
  }

  let update_button;
  let collect_button;
  if (isLoading) {
    update_button = <Spinner animation="border" />;
  } else {
    let deadline_week = new Date(order.deadline);
    deadline_week.setDate(deadline_week.getDate() + 7);
    if (deadline_week > Date.now()) {
      if (order.arrived && transaction !== null && !transaction.collected) {
        collect_button = (
          <Sender
            url={"/api/order/" + id + "/collected"}
            method="PUT"
            onSuccess={() => setRefetch(refetch + 1)}
          >
            <Button type="submit" variant="secondary">
              Recogido
            </Button>
          </Sender>
        );
      }
      if (order.member_num === parseInt(auth.num) || auth.role === "admin") {
        let arrived_button;
        if (!order.active && !order.arrived) {
          arrived_button = (
            <Sender
              url={"/api/order/" + id + "/arrive"}
              method="PUT"
              onSuccess={() => setRefetch(refetch + 1)}
            >
              <Button type="submit" variant="dark">
                Informar llegada
              </Button>
            </Sender>
          );
        }
        update_button = (
          <div>
            <ButtonGroup>
              <LinkContainer to={"/order/edit/" + id}>
                <Button variant="info">Modificar</Button>
              </LinkContainer>
              <Button variant="danger" onClick={() => setShowDelete(true)}>
                Cancelar
              </Button>
            </ButtonGroup>
            <br />
            {arrived_button}
          </div>
        );
      }
    }
  }

  const deadlineStr = printDate(order.deadline, true);

  const handleClose = () => setShowDelete(false);

  return (
    <Fetcher url={"/api/order/" + id} onFetch={setData} refetch={refetch}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row>
        <Col>
          <h1>{order.name}</h1>
        </Col>
        <Col className="text-right">
          <p>
            Fecha limite:
            <br />
            {deadlineStr}
            <br />
            {expired}
          </p>
          {update_button}
          {collect_button}
        </Col>
      </Row>
      <p>{order.description}</p>

      {showTransaction(order, transaction, auth.disabled, onSend)}
      <ShowOrderResults order={order} />

      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar la elminicacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Borrar permanentemente el pedido {order.name}?</p>
          <p>Si aceptas devolvera el dinero a quien haya pedido productos.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              delorder(order, auth.token, setIsLoading, setError, setRedirect)
            }
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fetcher>
  );
}

function showTransaction(order, transaction, disabled, onSend) {
  if (order.active && !disabled) {
    return (
      <PurchaseOrder
        order={order}
        purchase={transaction && transaction.order_purchase}
        onSend={(t) => onSend(t)}
      />
    );
  }
  if (transaction) {
    return <ShowOrderTransaction order={order} transaction={transaction} />;
  }
}

function delorder(order, token, setIsLoading, setError, setRedirect) {
  setIsLoading(true);
  fetch(url("/api/order/" + order.ID), {
    method: "DELETE",
    headers: { "x-authentication": token },
  }).then((response) => {
    if (!response.ok) {
      setError(
        "Ha ocurrido un error cancelando el pedido: " +
          response.status.toString() +
          " " +
          response.statusText
      );
      setIsLoading(false);
    } else {
      setRedirect(true);
    }
  });
}

export default ShowOrder;
