import React, { useContext } from "react";
import { Navbar, Nav, NavDropdown, Button, Form, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import mano from "./mano.svg";
import AuthContext from "./AuthContext";

function Head(props) {
  const auth = useContext(AuthContext);
  let location = useLocation();

  let adminNav;
  if (auth.role === "admin") {
    adminNav = (
      <NavDropdown title="Admin" id="admin">
        <LinkContainer to="/members/purchase">
          <NavDropdown.Item>Compra</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/members">
          <NavDropdown.Item>Socias</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/topup">
          <NavDropdown.Item>Recarga</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/members/add">
          <NavDropdown.Item>Nueva socia</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/transaction">
          <NavDropdown.Item>Transacciones</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/dues/report">
          <NavDropdown.Item>Cuotas</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/annual">
          <NavDropdown.Item>Listado Anual</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    );
  }

  const productNav = (
    <NavDropdown title="Almacen" id="almacen">
      <LinkContainer to="/products">
        <NavDropdown.Item>Productos</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/purchases">
        <NavDropdown.Item>Compras</NavDropdown.Item>
      </LinkContainer>
      {auth.role === "admin" && (
        <div>
          <LinkContainer to="/inventary">
            <NavDropdown.Item>Entradas Almacén</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to="/supplier/add">
            <NavDropdown.Item>Alta Proveedores</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to="/product/add">
            <NavDropdown.Item>Alta Codigos</NavDropdown.Item>
          </LinkContainer>
        </div>
      )}
    </NavDropdown>
  );

  let orderNav = (
    <LinkContainer to="/orders">
      <Nav.Link>Pedidos</Nav.Link>
    </LinkContainer>
  );
  if (auth.role === "order" || auth.role === "admin") {
    orderNav = (
      <NavDropdown title="Pedidos" id="admin">
        <LinkContainer to="/orders">
          <NavDropdown.Item>Pedidos</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/order/create">
          <NavDropdown.Item>Abrir pedido</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    );
  }

  let alert;
  let buyNav;
  if (auth.disabled) {
    alert = (
      <Alert variant="warning">
        Tu cuenta esta bloqueada pendiente de pagar la cuota mensual. Haz una
        recarga para activarla.
      </Alert>
    );
  } else {
    buyNav = (
      <LinkContainer to="/purchase">
        <Nav.Link>Comprar</Nav.Link>
      </LinkContainer>
    );
  }

  return (
    <div>
      <Navbar bg="light" expand="sm">
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              src={mano}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Garbanzo Negro"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-start" activeKey={location.pathname}>
            {buyNav}
            {productNav}
            {orderNav}
          </Nav>

          <Nav
            className="justify-content-end flex-grow-1 pre-3"
            activeKey={location.pathname}
          >
            <NavDropdown title="Ajustes" id="ajustes">
              <LinkContainer to="/password">
                <NavDropdown.Item>Cambiar contraseña</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>

            {adminNav}
          </Nav>
          <Form className="d-flex">
            <Button variant="outline-success" onClick={props.onLogout}>
              Salir
            </Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
      {alert}
    </div>
  );
}

export default Head;
