import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Fetcher from "../Fetcher";
import { printDate, printMoney, printMember } from "../util";

function DuesReport() {
  const [dues, setDues] = useState([]);

  const duesByMonth = dues.reduce((months, d) => {
    const date = new Date(Date.parse(d.date));
    const month = String(date.getFullYear()) + " - " + (date.getMonth() + 1);
    (months[month] = months[month] || []).push(d);
    return months;
  }, {});

  const months = Object.entries(duesByMonth).map(([month, dues]) => {
    const total = dues.reduce((total, d) => total + d.total, 0);
    const entries = dues.map((d) => (
      <tr key={d.ID}>
        <td>{printDate(d.date)}</td>
        <td>{printMember(d.member)}</td>
        <td>{printMoney(-d.total)}</td>
      </tr>
    ));
    return (
      <div key={month}>
        <h3>
          {month}: {printMoney(-total)}
        </h3>
        <Table className="text-center" responsive>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Socia</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>{entries}</tbody>
        </Table>
      </div>
    );
  });

  return (
    <Fetcher url="/api/dues" onFetch={setDues}>
      {months}
    </Fetcher>
  );
}

export default DuesReport;
