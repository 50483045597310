import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, InputGroup } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Sender from "./Sender";

function SignIn(props) {
  const native = process.env.REACT_APP_NATIVE;
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [noExpire, setNoExpire] = useState(Boolean(native));
  const [badAuth, setBadAuth] = useState(false);
  const [passVisible, setPassVisible] = useState(false);

  const onError = (status) => {
    if (status === 400) {
      setBadAuth(true);
      return true;
    }
    return false;
  };

  const togglePassVisible = () => {
    setPassVisible(!passVisible);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Sender
          url="/api/signin"
          body={{ login, password, noExpire }}
          onSuccess={(data) => props.onLogin(data.token, data.member)}
          onError={onError}
        >
          <Form.Group>
            <Form.Label>Nombre de acceso o email</Form.Label>
            <Form.Control
              placeholder="Nombre o email"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              isInvalid={badAuth}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Contraseña</Form.Label>
            <InputGroup>
              <Form.Control
                type={passVisible ? "input" : "password"}
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={badAuth}
              />
              <InputGroup.Text onClick={togglePassVisible}>
                {passVisible ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Nombre o contraseña invalidos.
            </Form.Control.Feedback>
          </Form.Group>

          {!native && (
            <Form.Group>
              <Form.Check
                type="switch"
                id="noExpire"
                label="Permanecer conectada con este navegador"
                onChange={(e) => setNoExpire(e.target.checked)}
              />
            </Form.Group>
          )}

          <Button variant="primary" type="submit" className="w-100 mt-3">
            Entra
          </Button>
        </Sender>
      </Row>
      <br />
      <p className="text-right">
        <Link to="/reset/">... olvidaste la contraseña</Link>
      </p>
    </div>
  );
}

export default SignIn;
