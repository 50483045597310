import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import OrderEditor from "./OrderEditor";
import Sender from "../Sender";

function CreateOrder() {
  const [body, setBody] = useState();
  const [order, setOrder] = useState(null);

  if (order !== null) {
    return <Navigate to={"/order/" + order.ID} />;
  }

  const disabled =
    !body ||
    !body.name ||
    body.products.find((p) => isNaN(p.price)) !== undefined;

  return (
    <Sender url="/api/order" body={body} onSuccess={setOrder}>
      <OrderEditor onChange={setBody} />
      <Form.Group as={Row}>
        <Col>
          <div className="text-right">
            <Button type="submit" disabled={disabled}>
              Abrir pedido
            </Button>
          </div>
        </Col>
      </Form.Group>
    </Sender>
  );
}

export default CreateOrder;
