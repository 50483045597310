import React, { useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Spinner, Alert, Button, Modal } from "react-bootstrap";
import MemberForm from "./MemberForm";
import Sender from "../Sender";
import Fetcher from "../Fetcher";
import AuthContext from "../AuthContext";
import { url } from "../util";

function MemberEditer() {
  const { num } = useParams();
  const auth = useContext(AuthContext);
  const [member, setMember] = useState({
    num: "",
    name: "",
    email: "",
    phone: "",
    role: "member",
  });
  const [error, setError] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const setMembers = (members) => {
    const mem = members.find((m) => m.num === parseInt(num));
    if (!mem) {
      setError("Numero de socia invalido");
    } else {
      if (member.num == "") {
        setMember({
          num: mem.num,
          name: mem.name,
          email: mem.email,
          phone: mem.phone,
          role: mem.role,
        });
      }
    }
  };

  const handleClose = () => setShowDelete(false);

  const delMember = () => {
    setLoading(true);
    fetch(url("/api/member/" + num), {
      headers: { "x-authentication": auth.token },
      method: "DELETE",
    }).then((response) => {
      if (!response.ok) {
        setError(
          "No pudo eliminar la socia: " +
            response.status.toString() +
            " " +
            response.statusText
        );
      } else {
        setRedirect(true);
      }
    });
  };

  if (redirect) {
    return <Navigate to="/members" />;
  }

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const invalid = !member.num || !member.name || !member.email;

  return (
    <Fetcher url="/api/member" onFetch={setMembers} oneShot>
      <Sender
        url={"/api/member/" + num}
        method="PUT"
        body={member}
        onSuccess={() => setRedirect(true)}
      >
        <MemberForm value={member} onChange={setMember} />
        <Row>
          <Button type="submit" disabled={invalid}>
            Guardar
          </Button>
          &nbsp;
          <LinkContainer to="/members">
            <Button variant="secondary">Cancelar</Button>
          </LinkContainer>
          <Col className="text-right">
            <Button variant="danger" onClick={() => setShowDelete(true)}>
              Eliminar
            </Button>
          </Col>
        </Row>

        <Modal show={showDelete} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar la elminicacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Borrar permanentemente la cuenta de {member.name}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={delMember}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </Sender>
    </Fetcher>
  );
}

export default MemberEditer;
