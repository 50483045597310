import { createContext } from "react";

export const AuthContext = createContext({
  num: 0,
  role: "",
  disabled: false,
  token: null,
});

export default AuthContext;
