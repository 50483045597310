import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function transactionOverlay(transaction) {
  let title;
  let content;
  switch (transaction.type) {
    case "purchase":
      title = "compra";
      content =
        transaction.purchase
          .map((p) => (p.product !== null ? p.product.name : p.code))
          .join(",") + ".";
      break;
    case "topup":
      if (transaction.total < 0) {
        title = "devolución";
      } else {
        title = "recarga";
      }
      content = transaction.topup.comment;
      break;
    case "order":
      if (!transaction.order) {
        title = "pedido";
        break;
      }
      title = "pedido de " + transaction.order.name;
      content = transaction.order_purchase.map((p) => {
        const name =
          p.order_product.product !== null
            ? p.order_product.product.name
            : p.order_product.code;
        return (
          <div key={"O" + transaction.ID + "-" + p.order_product.ID}>
            {name + ": " + p.amount}
            <br />
          </div>
        );
      });
      break;
    case "refund":
      if (!transaction.refund) {
        title = "devolución";
        break;
      }
      title = "devolución de " + transaction.refund.name;
      break;
    case "dues":
      title = "cuota mensual";
      break;
    default:
      title = "transacción";
  }
  return (
    <Popover>
      <Popover.Header>{title}</Popover.Header>
      {content && <Popover.Body>{content}</Popover.Body>}
    </Popover>
  );
}

function TransactionTr({ transaction, children }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const colorClass = transaction.total < 0 ? "table-danger" : "table-success";
  return (
    <>
      <LinkContainer to={"/transaction/" + transaction.ID} key={transaction.ID}>
        <tr
          className={colorClass}
          ref={target}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}
        </tr>
      </LinkContainer>
      <Overlay target={target.current} show={show}>
        {transactionOverlay(transaction)}
      </Overlay>
    </>
  );
}

export default TransactionTr;
