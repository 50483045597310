import React, { useState } from "react";
import { useParams } from "react-router-dom";
import InventaryEntry from "./InventaryEntry";
import Fetcher from "../Fetcher";

function ShowInventary() {
  const { id } = useParams();
  const [entry, setEntry] = useState({ ID: 0, products: [] });

  return (
    <Fetcher url={"/api/inventary/" + id} onFetch={setEntry}>
      <InventaryEntry entry={entry} />
    </Fetcher>
  );
}

export default ShowInventary;
