import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { GiPayMoney } from "react-icons/gi";
import Fetcher from "../Fetcher";
import { printMoney, printRole } from "../util";

function MemberList() {
  const [members, setMembers] = useState([]);

  const entries = members.map((member) => {
    const colorClass = member.disabled ? "table-danger" : "table-success";
    return (
      <LinkContainer key={member.num} to={"/member/" + member.num}>
        <tr className={colorClass}>
          <td>{member.num}</td>
          <td>{member.name}</td>
          <td>{member.login}</td>
          <td>{member.email}</td>
          <td>{member.phone}</td>
          <td>{printRole(member.role)}</td>
          <td>{printMoney(member.balance)}€</td>
          <td md={1}>
            <LinkContainer to={"/topup/" + member.num}>
              <GiPayMoney />
            </LinkContainer>
          </td>
        </tr>
      </LinkContainer>
    );
  });

  return (
    <Fetcher url="/api/member" onFetch={setMembers}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Login</th>
            <th>Email</th>
            <th>Telefono</th>
            <th>Rol</th>
            <th>Saldo</th>
          </tr>
        </thead>
        <tbody>{entries}</tbody>
      </Table>
    </Fetcher>
  );
}

export default MemberList;
