import React, { useState } from "react";
import { Table } from "react-bootstrap";
import icon from "./icon";
import TransactionTr from "./TransactionTr";
import Fetcher from "../Fetcher";
import { printMoney, printDate } from "../util";

function MyTransactions() {
  const [transactions, setTransactions] = useState([]);

  const entries = transactions.map((transaction) => {
    return (
      <TransactionTr key={transaction.ID} transaction={transaction}>
        <td>{icon(transaction)}</td>
        <td>{printDate(transaction.date)}</td>
        <td>{printMoney(transaction.total) + " €"}</td>
      </TransactionTr>
    );
  });

  return (
    <Fetcher url="/api/transaction/mine" onFetch={setTransactions}>
      <Table className="text-center">
        <thead>
          <tr>
            <th></th>
            <th>Fecha</th>
            <th>Cantidad</th>
          </tr>
        </thead>
        <tbody>{entries}</tbody>
      </Table>
    </Fetcher>
  );
}

export default MyTransactions;
