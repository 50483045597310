import React, { useState } from "react";
import { Table, Form, Row, Col } from "react-bootstrap";
import icon from "./icon";
import TransactionTr from "./TransactionTr";
import MemberPicker from "../member/MemberPicker";
import Fetcher from "../Fetcher";
import {
  date2string,
  daysAfterNow,
  printMoney,
  printDate,
  printTransactionID,
} from "../util";

const engType = {
  compra: "purchase",
  recarga: "topup",
  pedido: "order",
  devolucion: "refund",
  cuota: "dues",
};

function TransactionList() {
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(date2string(daysAfterNow(-30)));
  const [endDate, setEndDate] = useState(date2string(new Date()));
  const [member, setMember] = useState(null);
  const [proxy, setProxy] = useState(null);
  const [types, setTypes] = useState([]);

  let query = "start-date=" + startDate + "&end-date=" + endDate;
  if (member) {
    query += "&member=" + member.num;
  }
  if (proxy) {
    query += "&proxy=" + proxy.num;
  }
  query += types.map((t) => "&type=" + engType[t]).join("");

  const onTypeChange = (e) => {
    const newTypes = Array.from(e.target.selectedOptions, (o) => o.value);
    setTypes(newTypes);
  };

  const entries = transactions.map((transaction) => {
    let memberStr = "";
    if (transaction.member) {
      memberStr = transaction.member.name + " (" + transaction.member.num + ")";
    }
    return (
      <TransactionTr key={transaction.ID} transaction={transaction}>
        <td>{icon(transaction)}</td>
        <td>{printTransactionID(transaction)}</td>
        <td>{printDate(transaction.date)}</td>
        <td>{memberStr}</td>
        <td>{transaction.proxy ? transaction.proxy.name : ""}</td>
        <td>{printMoney(transaction.total) + " €"}</td>
      </TransactionTr>
    );
  });

  return (
    <div>
      <Form>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Desde:</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={endDate}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Hasta:</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
              max={Date.now()}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Typo:</Form.Label>
            <Form.Select value={types} onChange={onTypeChange} multiple>
              <option>compra</option>
              <option>recarga</option>
              <option>pedido</option>
              <option>devolucion</option>
              <option>cuota</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <MemberPicker member={member} onChange={setMember} allowDisabled />
        <MemberPicker
          member={proxy}
          onChange={setProxy}
          text="Por"
          allowDisabled
        />
      </Form>
      <br />
      <Fetcher url={"/api/transaction?" + query} onFetch={setTransactions}>
        <Table className="text-center" responsive>
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>Fecha</th>
              <th>Socia</th>
              <th>Por</th>
              <th>Cantidad</th>
            </tr>
          </thead>
          <tbody>{entries}</tbody>
        </Table>
      </Fetcher>
    </div>
  );
}

export default TransactionList;
