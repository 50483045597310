import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import Sender from "../Sender";

function CreateSupplier() {
  const [name, setName] = useState("");
  const [redirect, setRedirect] = useState(null);

  if (redirect !== null) {
    return <Navigate to="/inventary/" />;
  }

  const body = { name };

  return (
    <div>
      <h2 className="text-center">Alta de proveedores</h2>
      <Sender url="/api/supplier" body={body} onSuccess={setRedirect}>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={3}>
            Nombre
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              placeholder="nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col>
            <div className="text-right">
              <Button type="submit" disabled={!name}>
                Añadir proveedor
              </Button>
            </div>
          </Col>
        </Form.Group>
      </Sender>
    </div>
  );
}

export default CreateSupplier;
