import React, { useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { Row, Col, Button, Alert, Modal } from "react-bootstrap";
import ShowPurchase from "./ShowPurchase";
import ProductPicker from "../product/ProductPicker";
import MemberPicker from "../member/MemberPicker";
import Sender from "../Sender";
import { printMoney } from "../util";

function Purchase(props) {
  const [purchase, setPurchase] = useState([]);
  const [member, setMember] = useState(null);
  const [total, setTotal] = useState(0);
  const [transaction, setTransaction] = useState(null);
  const [noMoney, setNoMoney] = useState(false);
  const [disabledError, setDisabledError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const sender = useRef(null);

  const handleClose = () => setShowConfirmation(false);

  const submit = () => {
    handleClose();
    sender.current.click();
  };

  if (transaction !== null) {
    return <Navigate to={"/transaction/" + transaction.ID} />;
  }

  const url =
    props.member && member
      ? "/api/member/" + member.num + "/purchase"
      : "/api/purchase";

  const updatePurchase = (newPurchase) => {
    const add = (acc, p) => acc + p.price * p.amount;
    const newTotal = newPurchase.reduce(add, 0);
    setPurchase(newPurchase);
    setTotal(newTotal);
  };

  const body = () =>
    purchase.map((p) => {
      return {
        code: p.code,
        amount: p.amount,
      };
    });

  const onError = (status) => {
    if (status === 400) {
      setNoMoney(true);
      return true;
    }
    if (status === 402) {
      setDisabledError(true);
      return true;
    }
    return false;
  };

  const disabled = (props.member && !member) || total === 0;

  const displayedPurchase = purchase.map((p) => {
    return {
      code: p.code,
      product: p,
      amount: p.amount,
      price: p.price,
    };
  });

  return (
    <Sender url={url} body={body} onSuccess={setTransaction} onError={onError}>
      <button style={{ display: "none" }} type="submit" ref={sender} />
      {noMoney && (
        <Alert variant="warning">
          No tienes suficiente dinero para realizar esta compra.
        </Alert>
      )}
      {disabledError && (
        <Alert variant="warning">
          No puedes realizar compras por que tu cuenta esta bloqueada.
        </Alert>
      )}

      {props.member && <MemberPicker member={member} onChange={setMember} />}

      <ProductPicker amount picks={purchase} setPicks={updatePurchase} />
      <br />
      <Row>
        <Col>
          <h3>Total: {printMoney(total)}€</h3>
        </Col>
        <Col>
          <div className="text-right">
            <Button
              variant="primary"
              disabled={disabled}
              onClick={() => setShowConfirmation(true)}
            >
              Finalizar compra
            </Button>
          </div>
        </Col>
      </Row>

      <Modal show={showConfirmation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar la compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShowPurchase purchase={displayedPurchase} />
          <h3>Total: {printMoney(total)}€</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={disabled} onClick={submit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Sender>
  );
}

export default Purchase;
