import React, { useState } from "react";
import { Alert, Form, InputGroup, Button, Col, Row } from "react-bootstrap";
import { printMoney } from "../util";
import Sender from "../Sender";

function PurchaseOrder({ order, purchase, onSend }) {
  const [productAmounts, setProductAmounts] = useState(
    listProductAmounts(order, purchase)
  );
  const [total, setTotal] = useState(calculateTotal(productAmounts));
  const [noMoney, setNoMoney] = useState(false);
  const [disabledError, setDisabledError] = useState(false);

  const onSuccess = (transaction) => {
    onSend(transaction);
    setNoMoney(false);
    setDisabledError(false);
  };

  const onError = (status) => {
    if (status === 400) {
      setNoMoney(true);
      return true;
    }
    if (status === 402) {
      setDisabledError(true);
      return true;
    }
    return false;
  };

  let alert;
  if (noMoney) {
    alert = (
      <Alert variant="warning">
        No tienes suficiente dinero para realizar este pedido.
      </Alert>
    );
  }
  if (disabledError) {
    alert = (
      <Alert variant="warning">
        No puedes realizar compras por que tu cuenta esta bloqueada.
      </Alert>
    );
  }

  const formEntries = listFormEntries(
    productAmounts,
    setProductAmounts,
    setTotal
  );

  return (
    <Sender
      url={"/api/order/" + order.ID + "/purchase"}
      body={body(productAmounts)}
      onSuccess={onSuccess}
      onError={onError}
    >
      {alert}
      {formEntries}
      <Form.Group as={Row}>
        <Col className="text-right">
          <Button type="submit">
            {purchase ? "Actualizar" : "Realizar"} pedido
          </Button>
        </Col>
        <Col>
          <h3>Total: {printMoney(total)}€</h3>
        </Col>
      </Form.Group>
    </Sender>
  );
}

function listFormEntries(productAmounts, setProductAmounts, setTotal) {
  const setAmount = (index, amount) => {
    const newProductAmount = { ...productAmounts[index], amount };
    let newProductAmounts = [...productAmounts];
    newProductAmounts[index] = newProductAmount;
    const newTotal = calculateTotal(newProductAmounts);
    setProductAmounts(newProductAmounts);
    setTotal(newTotal);
  };

  return productAmounts.map((p, i) => (
    <Form.Group key={p.code} as={Row}>
      <Form.Label column className="text-right">
        {p.product.name} ({p.code}):
      </Form.Label>
      <Col>
        <InputGroup>
          <Form.Control
            type="number"
            min="0"
            placeholder="cantidad"
            value={p.amount}
            onChange={(e) => setAmount(i, e.target.value)}
          />
          <InputGroup.Text>{printMoney(p.price)}€</InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  ));
}

function calculateTotal(productAmounts) {
  const add = (acc, p) => acc + p.price * p.amount;
  return productAmounts.reduce(add, 0);
}

function listProductAmounts(order, purchase) {
  return order.products
    .map((p) => {
      p.amount = 0;
      if (purchase) {
        const my_purchase = purchase.find(
          (e) => e.order_product.code === p.code
        );
        if (my_purchase) {
          p.amount = my_purchase.amount;
        }
      }
      return p;
    })
    .sort((p1, p2) => {
      if (p1.product.name > p2.product.name) {
        return 1;
      }
      if (p1.product.name < p2.product.name) {
        return -1;
      }
      return 0;
    });
}

function body(productAmounts) {
  return productAmounts.map((p) => {
    return {
      order_product_id: p.ID,
      amount: parseInt(p.amount),
    };
  });
}

export default PurchaseOrder;
