import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import MemberAdder from "./member/MemberAdder";
import MemberEditer from "./member/MemberEditer";
import MemberList from "./member/MemberList";
import ProductList from "./product/ProductList";
import AnnualReport from "./product/AnnualReport";
import ShowProduct from "./product/ShowProduct";
import CreateProduct from "./product/CreateProduct";
import Inventary from "./inventary/Inventary";
import ShowInventary from "./inventary/ShowInventary";
import CreateSupplier from "./inventary/CreateSupplier";
import Dashboard from "./Dashboard";
import OwnPassword from "./OwnPassword";
import PurchaseList from "./purchase/PurchaseList";
import Purchase from "./purchase/Purchase";
import Topup from "./Topup";
import ShowTransaction from "./transaction/ShowTransaction";
import TransactionList from "./transaction/TransactionList";
import ShowOrder from "./order/ShowOrder";
import CreateOrder from "./order/CreateOrder";
import OrderList from "./order/OrderList";
import EditOrder from "./order/EditOrder";
import DuesList from "./dues/DuesList";
import DuesReport from "./dues/DuesReport";
import SignIn from "./SignIn";
import ResetRequest from "./ResetRequest";
import ResetPassword from "./ResetPassword";
import Head from "./Head";
import logo from "./logo.svg";

function Panel(props) {
  if (props.isLogged) {
    return <LogedPanel onLogout={props.onLogout} />;
  }
  return <UnlogedPanel onLogin={props.onLogin} />;
}

function LogedPanel(props) {
  return (
    <div>
      <BrowserRouter>
        <Head onLogout={props.onLogout} />
        <Container>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="members" element={<MemberList />} />
            <Route path="members/add" element={<MemberAdder />} />
            <Route path="members/purchase" element={<Purchase member />} />
            <Route path="member/:num" element={<MemberEditer />} />
            <Route path="annual" element={<AnnualReport />} />
            <Route path="products" element={<ProductList />} />
            <Route path="product/add" element={<CreateProduct />} />
            <Route path="product/:code" element={<ShowProduct />} />
            <Route path="inventary" element={<Inventary />} />
            <Route path="inventary/:id" element={<ShowInventary />} />
            <Route path="supplier/add" element={<CreateSupplier />} />
            <Route path="transaction" element={<TransactionList />} />
            <Route path="transaction/:id" element={<ShowTransaction />} />
            <Route path="password" element={<OwnPassword />} />
            <Route path="purchase" element={<Purchase />} />
            <Route path="purchases" element={<PurchaseList />} />
            <Route path="topup" element={<Topup />} />
            <Route path="topup/:num" element={<Topup />} />
            <Route path="orders" element={<OrderList />} />
            <Route path="order/create" element={<CreateOrder />} />
            <Route path="order/edit/:id" element={<EditOrder />} />
            <Route path="order/:id" element={<ShowOrder />} />
            <Route path="dues" element={<DuesList />} />
            <Route path="dues/report" element={<DuesReport />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </div>
  );
}

function UnlogedPanel(props) {
  return (
    <Container>
      <Row className="justify-content-center">
        <img src={logo} alt="Garbanzo Negro" />
      </Row>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn onLogin={props.onLogin} />} />
          <Route path="/reset/" element={<ResetRequest />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default Panel;
