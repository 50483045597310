import React, { useState, useContext } from "react";
import { Row, Form, Spinner, Alert } from "react-bootstrap";
import AuthContext from "./AuthContext";
import { ResponseError } from "./errors";
import { url as cleanUrl } from "./util";

function Sender({ url, method, body, onSuccess, onError, children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const auth = useContext(AuthContext);

  const submit = (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    const fetchMethod = method ? method : "POST";

    const bodyObj = typeof body === "function" ? body() : body;
    const bodyJson = JSON.stringify(bodyObj);
    fetch(cleanUrl(url), {
      headers: { "x-authentication": auth.token },
      method: fetchMethod,
      body: bodyJson,
    })
      .then((response) => {
        setIsLoading(false);
        if (!response.ok) {
          throw new ResponseError(response);
        }

        if (response.headers.get("content-type") !== "application/json") {
          return response.text();
        }
        return response.json();
      })
      .then(onSuccess)
      .catch((e) => {
        if (
          e instanceof ResponseError &&
          onError &&
          onError(e.response.status)
        ) {
          return;
        }

        setError(e.message);
      });
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center">
        <Spinner animation="border" />
      </Row>
    );
  }
  let errorStr;
  if (error) {
    errorStr = (
      <Alert variant="danger">
        Se produjo un error enviando datos: {error}
      </Alert>
    );
  }

  return (
    <Form onSubmit={submit}>
      {errorStr}
      {children}
    </Form>
  );
}

export default Sender;
