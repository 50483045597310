import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Row, Col, Button } from "react-bootstrap";
import OrderEditor from "./OrderEditor";
import Fetcher from "../Fetcher";
import Sender from "../Sender";

function EditOrder() {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [body, setBody] = useState();
  const [sent, setSent] = useState(false);

  if (sent) {
    return <Navigate to={"/order/" + id} />;
  }

  const disabled =
    !body ||
    !body.name ||
    body.products.find((p) => isNaN(p.price)) !== undefined;

  let order_editor;
  if (order) {
    order_editor = <OrderEditor order={order} onChange={setBody} />;
  }
  return (
    <Fetcher
      url={"/api/order/" + id}
      onFetch={(o) => setOrder(o.order)}
      oneShot
    >
      <Sender
        url={"/api/order/" + id}
        method="PUT"
        body={body}
        onSuccess={setSent}
      >
        {order_editor}
        <Form.Group as={Row}>
          <Col>
            <div className="text-right">
              <Button type="submit" disabled={disabled}>
                Actualizar pedido
              </Button>
              <LinkContainer to={"/order/" + id}>
                <Button variant="secondary">Cancelar</Button>
              </LinkContainer>
            </div>
          </Col>
        </Form.Group>
      </Sender>
    </Fetcher>
  );
}

export default EditOrder;
