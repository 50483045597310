import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Fetcher from "../Fetcher";
import { printMoney } from "../util";

function ProductList() {
  const [products, setProducts] = useState([]);

  const entries = products.map((product) => {
    return (
      <LinkContainer key={product.code} to={"/product/" + product.code}>
        <tr>
          <td>{product.code}</td>
          <td>{product.name}</td>
          <td>{printMoney(product.price)}</td>
          <td>{product.stock}</td>
        </tr>
      </LinkContainer>
    );
  });

  return (
    <div>
      <h2 className="text-center">Productos en almacén</h2>
      <Fetcher url="/api/product" onFetch={setProducts}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Código</th>
              <th>Producto</th>
              <th>Precio</th>
              <th>Existencias</th>
            </tr>
          </thead>
          <tbody>{entries}</tbody>
        </Table>
      </Fetcher>
    </div>
  );
}

export default ProductList;
