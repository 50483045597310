import React, { useState, useContext, useEffect } from "react";
import { Spinner, Alert, Row } from "react-bootstrap";
import AuthContext from "./AuthContext";
import { url as cleanUrl } from "./util";

function Fetcher({ url, refetch, oneShot, onFetch, children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUrl = () => {
      fetch(cleanUrl(url), {
        headers: { "x-authentication": auth.token },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              response.status.toString() + " " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          setIsLoading(false);
          onFetch(data);
          setError(null);
        })
        .catch((e) => {
          setError(e.message);
          setIsLoading(false);
        });
    };

    var clean = () => {};
    fetchUrl();
    if (!oneShot) {
      const timerID = setInterval(
        () => fetchUrl(),
        30000 // every 30 seconds
      );
      clean = () => clearInterval(timerID);
    }
    return clean;
  }, [url, refetch, oneShot, onFetch, auth.token]);

  if (isLoading) {
    return (
      <Row className="justify-content-md-center">
        <Spinner animation="border" />
      </Row>
    );
  }

  if (error != null) {
    console.log(error);
    return (
      <Alert variant="danger">
        Ha ocurrido un error cargando datos: {error}
      </Alert>
    );
  }

  return children;
}

export default Fetcher;
