import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Sender from "../Sender";
import Fetcher from "../Fetcher";

function OrderCards() {
  const [unarrived, setUnarrived] = useState([]);
  const [collectable, setCollectable] = useState([]);
  const [active, setActive] = useState([]);
  const [refetch, setRefetch] = useState(0);

  const unarrived_list = unarrived.map((o) => (
    <Card bg="dark" text="white" key={o.ID} as={Col} sm={4}>
      <Card.Body>
        <LinkContainer to={"/order/" + o.ID}>
          <div>
            <Card.Title>{o.name}</Card.Title>
            <Card.Text>{o.description}</Card.Text>
          </div>
        </LinkContainer>
        <Sender
          url={"/api/order/" + o.ID + "/arrive"}
          method="PUT"
          onSuccess={() => setRefetch(refetch + 1)}
        >
          <Button type="submit" variant="light">
            Informar llegada
          </Button>
        </Sender>
      </Card.Body>
    </Card>
  ));

  const collectable_list = collectable.map((t) => {
    const purchase_list = t.order_purchase.map((o) => {
      if (o.amount === 0) {
        return "";
      }
      return (
        <div key={o.order_product.code}>
          {o.order_product.product.name}: {o.amount}
          <br />
        </div>
      );
    });
    if (!t.order) {
      console.log(t);
      return <div></div>;
    }
    return (
      <Card bg="secondary" text="white" key={"T-" + t.ID} as={Col} sm={4}>
        <Card.Body>
          <LinkContainer to={"/order/" + t.order.ID}>
            <div>
              <Card.Title>{t.order.name}</Card.Title>
              <Card.Text>{purchase_list}</Card.Text>
            </div>
          </LinkContainer>
          <Sender
            url={"/api/order/" + t.order.ID + "/collected"}
            method="PUT"
            onSuccess={() => setRefetch(refetch + 1)}
          >
            <Button type="submit" variant="light">
              Recogido
            </Button>
          </Sender>
        </Card.Body>
      </Card>
    );
  });

  const active_list = active.map((o) => (
    <LinkContainer to={"/order/" + o.ID} key={o.ID}>
      <Card bg="info" text="white" as={Col} sm={4}>
        <Card.Body>
          <Card.Title>{o.name}</Card.Title>
          <Card.Text>{o.description}</Card.Text>
          <Button variant="light">Realizar pedido</Button>
        </Card.Body>
      </Card>
    </LinkContainer>
  ));

  return (
    <Row>
      <Fetcher
        url={"/api/order/unarrived"}
        onFetch={setUnarrived}
        refetch={refetch}
      >
        {unarrived_list}
      </Fetcher>
      <Fetcher
        url={"/api/order/collectable"}
        onFetch={setCollectable}
        refetch={refetch}
      >
        {collectable_list}
      </Fetcher>
      <Fetcher url={"/api/order/active"} onFetch={setActive}>
        {active_list}
      </Fetcher>
    </Row>
  );
}

export default OrderCards;
