import React, { useState } from "react";
import Fetcher from "../Fetcher";
import { date2string } from "../util";

function transactionsPerMonth(transactions) {
  let productsPerMonth = {};

  const incProduct = (date, name, amount) => {
    if (amount === 0) {
      return;
    }

    const strDate = String(date.getFullYear()) + " - " + (date.getMonth() + 1);
    if (productsPerMonth[strDate] === undefined) {
      productsPerMonth[strDate] = {};
    }
    if (productsPerMonth[strDate][name] === undefined) {
      productsPerMonth[strDate][name] = amount;
    } else {
      productsPerMonth[strDate][name] += amount;
    }
  };

  transactions.forEach((t) => {
    const date = new Date(Date.parse(t.date));
    const monthDate = new Date(date.getFullYear(), date.getMonth());
    switch (t.type) {
      case "purchase":
        t.purchase.forEach((e) =>
          incProduct(monthDate, e.product.name, e.amount)
        );
        break;
      case "order":
        t.order_purchase.forEach((e) =>
          incProduct(monthDate, e.order_product.product.name, e.amount)
        );
        break;
      default:
        return;
    }
  });

  return productsPerMonth;
}

function AnnualReport() {
  const [data, setData] = useState([]);

  const setTransactions = (transactions) => {
    if (data.length == 0) {
      const d = transactionsPerMonth(transactions);
      setData(d);
    }
  };

  let dates = Object.keys(data);
  dates = dates.sort((a, b) => b.localeCompare(a));
  const months = dates.map((date) => {
    let products = Object.keys(data[date]);
    products = products.sort((a, b) =>
      data[date][a] < data[date][b] ? 1 : -1
    );
    const productList = products.map((product) => (
      <li key={date + "-" + product}>
        {product}: {data[date][product]}
      </li>
    ));
    return (
      <div>
        <h3>{date}</h3>
        <ul>{productList}</ul>
      </div>
    );
  });

  const now = new Date();
  const end = date2string(now);
  const start = date2string(new Date(now.getFullYear() - 1, now.getMonth()));
  return (
    <Fetcher
      url={
        "/api/transaction?start-date=" +
        start +
        "&end-date=" +
        end +
        "&type=purchase&type=order"
      }
      onFetch={setTransactions}
      oneShot
    >
      {months}
    </Fetcher>
  );
}

export default AnnualReport;
