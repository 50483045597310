import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import { printMoney, printDate, printInventaryID } from "../util";

function InventaryEntry(props) {
  const products = props.entry.products.map((p) => (
    <tr key={p.code}>
      <td>{p.product !== null ? p.product.name : p.code}</td>
      <td>{p.stock}</td>
      <td>{p.price !== null ? printMoney(p.price) : ""}</td>
    </tr>
  ));

  return (
    <div>
      <Row>
        {props.entry.supplier && (
          <Col>
            <h3>{props.entry.supplier.name}</h3>
          </Col>
        )}
        <Col>
          <p className="text-right">
            {props.entry.ID && (
              <div>
                {printDate(props.entry.date)} {printInventaryID(props.entry)}
                <br />
              </div>
            )}
            {props.entry.member && props.entry.member.name}
          </p>
        </Col>
      </Row>
      <p>{props.entry.comment}</p>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Producto</th>
            <th>Stock</th>
            <th>Precio</th>
          </tr>
        </thead>
        <tbody>{products}</tbody>
      </Table>
    </div>
  );
}

export default InventaryEntry;
