import React, { useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import MemberPicker from "./member/MemberPicker";
import { Form, Col, Row, Button } from "react-bootstrap";
import Sender from "./Sender";
import PriceEditor from "./PriceEditor";

function Topup() {
  const { num } = useParams();
  const [member, setMember] = useState(null);
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState("");
  const [transaction, setTransaction] = useState(null);

  if (transaction !== null) {
    return <Navigate to={"/transaction/" + transaction.ID} />;
  }

  const body = () => {
    if (member === null) {
      return null;
    }
    return {
      member: member.num,
      amount: amount,
      comment,
    };
  };

  return (
    <Sender url="/api/topup" body={body} onSuccess={setTransaction}>
      <MemberPicker
        member={member}
        onChange={setMember}
        num={parseInt(num)}
        allowDisabled
      />
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={2}>
          Recarga
        </Form.Label>
        <Col sm={10}>
          <PriceEditor value={amount} onChange={setAmount} />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={2}>
          Comentario
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            placeholder="..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={{ span: 10, offset: 2 }}>
          <Button type="submit" disabled={member === null || isNaN(amount)}>
            Recarga
          </Button>
        </Col>
      </Form.Group>
    </Sender>
  );
}

export default Topup;
