import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import ProductPicker from "../product/ProductPicker";
import Fetcher from "../Fetcher";
import { date2string, time2string, daysAfterNow } from "../util";

function order2picks(order) {
  return order.products.map((p) => {
    const name = p.product !== null ? p.product.name : p.code;
    return {
      code: p.code,
      name: name,
      price: p.price,
    };
  });
}

function genBody(name, description, date, time, picks) {
  const products = picks.map((p) => {
    return { code: p.code, price: p.price };
  });

  let deadline = new Date(date);
  const timeParts = time.split(":");
  deadline.setHours(timeParts[0], timeParts[1]);

  return { name, description, deadline, products };
}

function OrderEditor(props) {
  const [name, _setName] = useState(props.order ? props.order.name : "");
  const [description, _setDescription] = useState(
    props.order ? props.order.description : ""
  );
  const [deadlineDate, _setDeadlineDate] = useState(
    props.order
      ? date2string(new Date(props.order.deadline))
      : date2string(daysAfterNow(3))
  );
  const [deadlineTime, _setDeadlineTime] = useState(
    props.order ? time2string(new Date(props.order.deadline)) : "23:59"
  );
  const [picks, _setPicks] = useState(
    props.order ? order2picks(props.order) : []
  );
  const [prevOrders, setPrevOrders] = useState(null);

  const setName = (name) => {
    _setName(name);
    const body = genBody(name, description, deadlineDate, deadlineTime, picks);
    props.onChange(body);
  };
  const setDescription = (description) => {
    _setDescription(description);
    const body = genBody(name, description, deadlineDate, deadlineTime, picks);
    props.onChange(body);
  };
  const setDeadlineDate = (str) => {
    _setDeadlineDate(str);
    const body = genBody(name, description, str, deadlineTime, picks);
    props.onChange(body);
  };
  const setDeadlineTime = (str) => {
    _setDeadlineTime(str);
    const body = genBody(name, description, deadlineDate, str, picks);
    props.onChange(body);
  };
  const setPicks = (picks) => {
    _setPicks(picks);
    const body = genBody(name, description, deadlineDate, deadlineTime, picks);
    props.onChange(body);
  };

  const fillOrder = (name) => {
    if (!name) {
      return;
    }

    const prevOrder = prevOrders.find((o) => o.name === name);
    _setName(name);
    _setDescription(prevOrder.description);
    const prevPicks = order2picks(prevOrder);
    setPicks(prevPicks);

    const body = genBody(
      name,
      prevOrder.description,
      deadlineDate,
      deadlineTime,
      prevPicks
    );
    props.onChange(body);
  };

  const orderOptions = prevOrders
    ? [<option key=""></option>].concat(
        prevOrders.map((o) => <option key={o.name}>{o.name}</option>)
      )
    : "";

  let order_picker;
  if (!props.order) {
    order_picker = (
      <div>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={3}>
            Repetir pedido
          </Form.Label>
          <Col sm={9}>
            <Fetcher url="/api/order/picks" onFetch={setPrevOrders}>
              <Form.Select onChange={(e) => fillOrder(e.target.value)}>
                {orderOptions}
              </Form.Select>
            </Fetcher>
          </Col>
        </Form.Group>
        <hr />
      </div>
    );
  }

  return (
    <div>
      {order_picker}
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={3}>
          Nombre
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            placeholder="nombre del pedido"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={3}>
          Descripción
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            as="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={3}>
          Fecha límite
        </Form.Label>
        <Col sm={5}>
          <Form.Control
            type="date"
            value={deadlineDate}
            onChange={(e) => setDeadlineDate(e.target.value)}
          />
        </Col>
        <Col sm={4}>
          <Form.Control
            type="time"
            value={deadlineTime}
            onChange={(e) => setDeadlineTime(e.target.value)}
          />
        </Col>
      </Form.Group>
      <ProductPicker picks={picks} setPicks={setPicks} price />
    </div>
  );
}

export default OrderEditor;
