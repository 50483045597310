import React, { useState } from "react";
import { Table, OverlayTrigger, Popover } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Fetcher from "../Fetcher";
import { printDate } from "../util";

function orderOverlay(order) {
  const content = order.products.map((p) => {
    if (p.product === null) {
      return null;
    }

    return (
      <div key={"OL" + order.ID + "-" + p.ID}>
        {p.product.name}
        <br />
      </div>
    );
  });

  return (
    <Popover>
      <Popover.Header>{order.description}</Popover.Header>
      <Popover.Body>{content}</Popover.Body>
    </Popover>
  );
}

function OrderList() {
  const [orders, setOrders] = useState([]);

  const entries = orders.map((order) => (
    <OverlayTrigger key={order.ID} overlay={orderOverlay(order)}>
      <LinkContainer to={"/order/" + order.ID}>
        <tr className={order.active ? "table-primary" : "table-secondary"}>
          <td>{printDate(order.deadline)}</td>
          <td>{order.name}</td>
        </tr>
      </LinkContainer>
    </OverlayTrigger>
  ));

  return (
    <Fetcher url="/api/order" onFetch={setOrders}>
      <Table className="text-center" responsive>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>{entries}</tbody>
      </Table>
    </Fetcher>
  );
}

export default OrderList;
