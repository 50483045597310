import React from "react";
import { Table, OverlayTrigger, Popover } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { printDate, printMoney, printInventaryID } from "../util";

function supplier(entry) {
  if (entry.supplier) {
    return entry.supplier.name;
  }
  return "";
}

function inventaryOverlay(entry) {
  const content = entry.products.map((p) => {
    const stock = p.stock ? p.stock : "";
    const price = p.price !== null ? printMoney(p.price) : "";
    const name = p.product !== null ? p.product.name : p.code;

    return (
      <div key={"I" + entry.ID + "-" + p.ID}>
        {p.comment && <p>p.comment</p>}
        {name + ": " + stock + " " + price}
        <br />
      </div>
    );
  });

  return (
    <Popover>
      <Popover.Header>{supplier(entry)}</Popover.Header>
      <Popover.Body>{content}</Popover.Body>
    </Popover>
  );
}

function InventaryList(props) {
  const entries = props.inventary.map((i) => (
    <OverlayTrigger key={i.ID} overlay={inventaryOverlay(i)}>
      <LinkContainer to={"/inventary/" + i.ID}>
        <tr>
          <td>{printInventaryID(i)}</td>
          <td>{printDate(i.date)}</td>
          <td>{i.member && i.member.name}</td>
          <td>{supplier(i)}</td>
        </tr>
      </LinkContainer>
    </OverlayTrigger>
  ));

  return (
    <Table className="text-center" responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Fecha</th>
          <th>Por</th>
          <th>Proveedor</th>
        </tr>
      </thead>
      <tbody>{entries}</tbody>
    </Table>
  );
}

export default InventaryList;
