import React from "react";
import { FaShoppingBasket, FaMoneyBillAlt } from "react-icons/fa";
import { FaHouseUser } from "react-icons/fa6";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { HiClipboardCopy, HiClipboardList } from "react-icons/hi";

function icon(transaction) {
  switch (transaction.type) {
    case "purchase":
      return <FaShoppingBasket />;
    case "topup":
      if (transaction.total < 0) {
        return <GiReceiveMoney />;
      }
      return <GiPayMoney />;
    case "order":
      return <HiClipboardList />;
    case "refund":
      return <HiClipboardCopy />;
    case "dues":
      return <FaHouseUser />;
    default:
      return <FaMoneyBillAlt />;
  }
}

export default icon;
