import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";

function PasswordForm(props) {
  const [pass2, setPass2] = useState("");
  const [passValid, setPassValid] = useState(true);
  const [passMatch, setPassMatch] = useState(true);

  const onPassChange = (e) => {
    const password = e.target.value;
    props.onChange(password);

    const passValid = password.length >= 8;
    setPassValid(passValid);
    props.setValid(passValid && passMatch);
  };

  const onPass2Change = (e) => {
    const password = e.target.value;
    setPass2(password);

    const passMatch = props.password === password;
    setPassMatch(passMatch);
    props.setValid(passValid && passMatch);
  };

  return (
    <div>
      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={4}>
          Nueva contraseña
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Nueva contraseña"
            type="password"
            value={props.password}
            onChange={onPassChange}
            isInvalid={!passValid}
          />
          <Form.Control.Feedback type="invalid">
            La contraseña es demasiado corta.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label as="legend" column sm={4}>
          Confirme la contraseña
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Repita la nueva contraseña"
            type="password"
            value={pass2}
            onChange={onPass2Change}
            isInvalid={!passMatch}
          />
          <Form.Control.Feedback type="invalid">
            Las contraseñas no coinciden.
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </div>
  );
}

export default PasswordForm;
