import React, { useState } from "react";
import { Form } from "react-bootstrap";

function str2price(str) {
  let negative = false;
  if (str[0] === "-") {
    str = str.substring(1);
    negative = true;
  }

  let value = str.split(",");
  let cents = 0;

  if (value.length > 2) {
    return NaN;
  }

  if (value.length === 1) {
    value = str.split(".");
  }

  if (value.length === 2) {
    if (value[1].length > 2) {
      return NaN;
    }

    cents = parseInt(value[1]);
    if (value[1].length === 1) {
      cents = cents * 10;
    }
    if (cents > 99) {
      return NaN;
    }
  }

  let price = parseInt(value[0]) * 100 + cents;
  if (negative) {
    price = -price;
  }
  return price;
}

function price2str(price) {
  let centStr = (price % 100).toString();
  if (centStr.length === 1) {
    centStr = "0" + centStr;
  }
  return Math.floor(price / 100) + "," + centStr;
}

function PriceEditor(props) {
  const [value, setValue] = useState(price2str(props.value));

  const change = (e) => {
    setValue(e.target.value);
    const price = str2price(e.target.value);
    props.onChange(price);
  };

  const invalid = isNaN(props.value);

  return <Form.Control value={value} onChange={change} isInvalid={invalid} />;
}

export default PriceEditor;
