import React, { useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Fetcher from "../Fetcher";
import ShowPurchase from "../purchase/ShowPurchase";
import { printMoney, printDate, printTransactionID } from "../util";

function ShowTransaction() {
  const { id } = useParams();
  const [transaction, setTransaction] = useState({ ID: 0 });

  let show_list;
  switch (transaction.type) {
    case "purchase":
      show_list = <ShowPurchase purchase={transaction.purchase} />;
      break;
    case "topup":
      show_list = <p>{transaction.topup.comment}</p>;
      break;
    case "order":
      return <Navigate to={"/order/" + transaction.order.ID} />;
    case "refund":
      return <Navigate to={"/order/" + transaction.refund.ID} />;
    case "dues":
      return <Navigate to={"/dues"} />;
    default:
      show_list = null;
  }

  return (
    <Fetcher url={"/api/transaction/" + id} onFetch={setTransaction}>
      {show_list}
      <Row>
        <Col>
          <h3>Total: {printMoney(transaction.total)}€</h3>
          {transaction.member && <p>{transaction.member.name}</p>}
        </Col>
        <Col>
          <p className="text-right">
            {printDate(transaction.date)} {printTransactionID(transaction)}
            {transaction.proxy && (
              <span>
                <br />
                por: {transaction.proxy.name}
              </span>
            )}
          </p>
        </Col>
      </Row>
    </Fetcher>
  );
}

export default ShowTransaction;
