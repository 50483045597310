import React, { useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Fetcher from "../Fetcher";

function MemberPicker({ member, num, text, onChange, allowDisabled }) {
  const [members, setStateMembers] = useState([]);
  const selected = member ? [member] : [];

  const setMembers = (members) => {
    const newMembers = members.map((m) => {
      m.numStr = m.num.toString();
      if (!m.login) {
        m.login = "";
      }
      if (allowDisabled) {
        m.disabled = false;
      }
      return m;
    });
    setStateMembers(newMembers);

    if (!member && num) {
      const m = newMembers.find((m) => m.num === num);
      onChange(m);
    }
  };

  const pickerText = text ? text : "Socia";

  return (
    <Fetcher url="/api/member" onFetch={setMembers}>
      <Row>
        <Col sm={4}>
          <br />
          <h4 className="text-right">{pickerText}:</h4>
        </Col>
        <Form.Group as={Col} sm={2}>
          <Form.Label>Num</Form.Label>
          <Typeahead
            id="member-num"
            labelKey="numStr"
            options={members}
            onChange={(m) => onChange(m[0])}
            selected={selected}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Login</Form.Label>
          <Typeahead
            id="member-login"
            labelKey="login"
            options={members}
            onChange={(m) => onChange(m[0])}
            selected={selected}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Nombre</Form.Label>
          <Typeahead
            id="member-name"
            labelKey="name"
            options={members}
            onChange={(m) => onChange(m[0])}
            selected={selected}
          />
        </Form.Group>
      </Row>
      <hr />
    </Fetcher>
  );
}

export default MemberPicker;
