function printMoney(money) {
  return (money / 100).toFixed(2);
}

function printDate(date, time) {
  const d = new Date(date);
  let dateStr = d.toLocaleDateString();
  if (time) {
    dateStr += " " + d.toLocaleTimeString();
  }
  return dateStr;
}

function printRole(role) {
  switch (role) {
    case "admin":
      return "admin";
    case "order":
      return "pedidos";
    default:
      return "socia";
  }
}

function printTransactionID(transaction) {
  return printID("T", transaction);
}

function printInventaryID(inventary) {
  return printID("I", inventary);
}

function printSupplierID(supplier) {
  return printID("S", supplier);
}

function printID(pre, item) {
  return pre + "-" + item.ID.toString().padStart(5, "0");
}

function printMember(member) {
  if (member) {
    return member.name + " (" + member.num + ")";
  } else {
    return "-";
  }
}

function url(path) {
  let api = process.env.REACT_APP_API;
  if (!api) {
    api = "";
  }
  return api + path;
}

function date2string(date) {
  return date.toISOString().split("T")[0];
}

function daysAfterNow(days) {
  let date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}

function time2string(date) {
  return date.toTimeString().slice(0, 5);
}

function deepEqual(obj1, obj2) {
  if (obj1 instanceof Object) {
    if (!(obj2 instanceof Object)) {
      return false;
    }
    return Object.keys(obj1).every((key) => deepEqual(obj1[key], obj2[key]));
  }

  return obj1 === obj2;
}

export {
  printMoney,
  printDate,
  printRole,
  printMember,
  url,
  date2string,
  time2string,
  daysAfterNow,
  printTransactionID,
  printInventaryID,
  printSupplierID,
  deepEqual,
};
