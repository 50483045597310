import React, { useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Form, Col, Row, Spinner, Alert, Button, Modal } from "react-bootstrap";
import Sender from "../Sender";
import Fetcher from "../Fetcher";
import AuthContext from "../AuthContext";
import { url, printMoney } from "../util";

function ShowProduct() {
  const { code } = useParams();
  const auth = useContext(AuthContext);
  const [product, _setProduct] = useState({
    price: 0,
    stock: 0,
  });
  const [name, setName] = useState("");
  const [newCode, setNewCode] = useState(code);
  const [error, setError] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const setProduct = (p) => {
    if (p.price == 0) {
      _setProduct(p);
      setName(p.name);
    }
  };

  if (redirect) {
    return <Navigate to="/products" />;
  }

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const handleClose = () => setShowDelete(false);

  const delProduct = () => {
    setLoading(true);
    fetch(url("/api/product/" + code), {
      headers: { "x-authentication": auth.token },
      method: "DELETE",
    }).then((response) => {
      if (!response.ok) {
        setError(
          "No pudo eliminar el producto: " +
            response.status.toString() +
            " " +
            response.statusText
        );
      } else {
        setRedirect(true);
      }
    });
  };

  const invalid = !name;

  const body = {
    name,
    code: parseInt(newCode),
    price: product.price,
    stock: -1,
  };

  return (
    <Fetcher url={"/api/product/" + code} onFetch={setProduct} oneShot>
      <Sender
        url={"/api/product/" + code}
        method="PUT"
        body={body}
        onSuccess={() => setRedirect(true)}
      >
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Codigo:</Form.Label>
            <Form.Control
              type="number"
              placeholder="codigo"
              value={newCode}
              onChange={(e) => setNewCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={10}>
            <Form.Label>Producto:</Form.Label>
            <Form.Control
              placeholder="nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Precio:</Form.Label>
            <p>{printMoney(product.price)}</p>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Cantidad:</Form.Label>
            <p>{product.stock}</p>
          </Form.Group>
        </Row>
        <Row>
          <Button type="submit" disabled={invalid}>
            Guardar
          </Button>
          &nbsp;
          <LinkContainer to="/products">
            <Button variant="secondary">Cancelar</Button>
          </LinkContainer>
          <Col className="text-right">
            <Button variant="danger" onClick={() => setShowDelete(true)}>
              Eliminar
            </Button>
          </Col>
        </Row>

        <Modal show={showDelete} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar la elminicacion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Borrar permanentemente el producto {product.name}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={delProduct}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </Sender>
    </Fetcher>
  );
}

export default ShowProduct;
