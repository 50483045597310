import React from "react";
import { Table } from "react-bootstrap";
import { printMoney } from "../util";

function ShowPurchase(props) {
  const entries = props.purchase.map((p) => {
    return (
      <tr key={p.code}>
        <td>{p.code}</td>
        <td>{p.product !== null ? p.product.name : p.code}</td>
        <td>{printMoney(p.price) + " €"}</td>
        <td>{p.amount}</td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Codigo</th>
          <th>Nombre</th>
          <th>Precio</th>
          <th>Cantidad</th>
        </tr>
      </thead>
      <tbody>{entries}</tbody>
    </Table>
  );
}

export default ShowPurchase;
