import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Alert, Form, Col, Row, Button, Spinner } from "react-bootstrap";
import PasswordForm from "./PasswordForm";
import Sender from "./Sender";
import { url } from "./util";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState("");
  const [validPass, setValidPass] = useState(false);
  const [validToken, setValidToken] = useState("loading");
  const [needsLogin, setNeedsLogin] = useState(false);
  const [success, setSuccess] = useState(false);
  const { token } = useParams();

  useEffect(() => {
    if (validToken === "loading") {
      fetch(url("/api/reset/" + token))
        .then((response) => {
          setValidToken(response.ok);
          return response.json();
        })
        .then((member) => {
          setNeedsLogin(!member.login);
        })
        .catch((e) => {
          console.log("Error with reset: " + e.message);
          setValidToken("");
        });
    }
  });

  if (validToken === "loading") {
    return (
      <Row className="justify-content-center">
        <Spinner animation="border" />
      </Row>
    );
  } else if (!validToken) {
    return (
      <div>
        <Alert variant="danger">
          La dirección de recuperación de la contraseña es invalida. Recuerda
          que solo son validas por 7 días
        </Alert>
        <p className="text-right">
          <Link to="/reset/">... vuelve a pedir que te envie el email</Link>
        </p>
      </div>
    );
  }

  if (success) {
    return (
      <Alert variant="success">
        Contraseña cambiada con exito, ahora ya puedes{" "}
        <Link to="/">entrar con tu nueva contraseña</Link>
      </Alert>
    );
  }

  const body = needsLogin ? { password, login } : { password };
  const disabled = !validPass || (needsLogin && !login);

  let head = <h2>Cambio de contraseña</h2>;
  if (needsLogin) {
    head = (
      <div>
        <h2>Configura tu nombre de acceso y contraseña</h2>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={4}>
            Nombre de acceso
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              placeholder="nombre de acceso"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              isInvalid={!login}
            />
          </Col>
        </Form.Group>
      </div>
    );
  }

  return (
    <Sender
      url={"/api/reset/" + token}
      method="PUT"
      body={body}
      onSuccess={() => setSuccess(true)}
    >
      <br />
      {head}
      <PasswordForm
        password={password}
        onChange={setPassword}
        setValid={setValidPass}
      />
      <Form.Group as={Row}>
        <Col sm={{ offset: 4, span: 8 }}>
          <Button type="submit" variant="primary" disabled={disabled}>
            Cambiar la contraseña
          </Button>
        </Col>
      </Form.Group>
    </Sender>
  );
}

export default ResetPassword;
