import React, { useState, useContext } from "react";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ProductPicker from "../product/ProductPicker";
import MemberPicker from "../member/MemberPicker";
import AuthContext from "../AuthContext";
import Fetcher from "../Fetcher";
import { date2string, daysAfterNow, printDate } from "../util";

function PurchaseList() {
  const auth = useContext(AuthContext);
  const [purchases, setPurchases] = useState([]);
  const [startDate, setStartDate] = useState(date2string(daysAfterNow(-30)));
  const [endDate, setEndDate] = useState(date2string(new Date()));
  const [members, setMembers] = useState([]);
  const [products, setProducts] = useState([]);

  const setTransactions = (transactions) => {
    const pur = transactions
      .map((t) => {
        let purchases = t.purchase;
        if (products.length !== 0) {
          purchases = purchases.filter(
            (p) => products.find((prod) => p.code === prod.code) !== undefined
          );
        }
        return purchases.map((p) => {
          return {
            ID: p.ID,
            date: t.date,
            product: p.product.name,
            code: p.code,
            amount: p.amount,
            member: t.member.name,
            num: t.member.num,
            transaction: t.ID,
          };
        });
      })
      .flat();
    setPurchases(pur);
  };

  const admin = auth.role === "admin";

  let query = "type=purchase&start-date=" + startDate + "&end-date=" + endDate;
  query += members.map((m) => "&member=" + m.num).join("");
  query += products.map((p) => "&product=" + p.code).join("");

  const entries = purchases.map((p) => {
    return (
      <LinkContainer to={"/transaction/" + p.transaction} key={p.ID}>
        <tr>
          <td>{printDate(p.date)}</td>
          <td>{p.product}</td>
          <td>{p.amount}</td>
          {admin && <td>{p.member}</td>}
        </tr>
      </LinkContainer>
    );
  });

  const setMember = (m) => {
    let newMembers = [...members];
    newMembers.push(m);
    setMembers(newMembers);
  };
  const delMember = (index) => {
    let newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };
  let memberPicker;
  if (admin) {
    const rows = members.map((member, i) => (
      <Row key={member.num}>
        <Col>
          <p>{member.name}</p>
        </Col>
        <Col xs={1}>
          <Button variant="danger" onClick={() => delMember(i)}>
            -
          </Button>
        </Col>
      </Row>
    ));
    memberPicker = (
      <Col xs={12} sm={6}>
        {rows}
        <MemberPicker onChange={setMember} allowDisabled />
      </Col>
    );
  }

  return (
    <div>
      <Form>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Desde:</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={endDate}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Hasta:</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
              max={Date.now()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <ProductPicker picks={products} setPicks={setProducts} noPrice />
          </Col>
          {memberPicker}
        </Row>
      </Form>
      <br />
      <Fetcher
        url={(admin ? "/api/transaction?" : "/api/transaction/mine?") + query}
        onFetch={setTransactions}
      >
        <Table className="text-center" responsive>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Producto</th>
              <th>Cantidad</th>
              {admin && <th>Socia</th>}
            </tr>
          </thead>
          <tbody>{entries}</tbody>
        </Table>
      </Fetcher>
    </div>
  );
}

export default PurchaseList;
